import { preloadImages } from './utils';
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable.js';
import { InertiaPlugin } from 'gsap/InertiaPlugin.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable, InertiaPlugin);

var slideDelay = 6;
var slideDuration = 0.6;

var slides = document.querySelectorAll(".slide");
var prevButton = document.querySelector("#prevButton");
var nextButton = document.querySelector("#nextButton");

var numSlides = slides.length;

for (var i = 0; i < numSlides; i++) {
  gsap.set(slides[i], {
   
    xPercent: i * 100
  });
}

var timer = gsap.delayedCall(slideDelay, autoPlay);

var animation = gsap.to(slides, {
  duration: 6, 
  xPercent: "+=" + (numSlides * 100),
  ease: "none",
  paused: true,
  repeat: -1,
  modifiers: {
    xPercent: gsap.utils.wrap(-100, (numSlides - 1) * 100)
  }
});

var proxy = document.createElement("div");
gsap.set(proxy, { x: 0 });
var slideAnimation = gsap.to({}, {duration: 0.1});
var slideWidth = 0;
var wrapWidth = 0;
resize();

var draggable = new Draggable(proxy, {
  trigger: ".slides-container",
  throwProps: true,
  onPress: updateDraggable,
  onDrag: updateProgress,
  onThrowUpdate: updateProgress,
  snap: {     
    x: gsap.utils.snap(slideWidth)
  }
});

window.addEventListener("resize", resize);

prevButton.addEventListener("click", function() {
  animateSlides(1);
});

nextButton.addEventListener("click", function() {
  animateSlides(-1);
});

function updateDraggable() {
  timer.restart(true);
  slideAnimation.kill();
  this.update();
}


function animateSlides(direction) {
  timer.restart(true);
  slideAnimation.kill();
  
  var x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);
  
  slideAnimation = gsap.to(proxy, {
    duration: slideDuration,
    x: x,
    onUpdate: updateProgress
  });  
}

function autoPlay() {  
  if (draggable.isPressed || draggable.isDragging || draggable.isThrowing) {
   timer.restart(true);
  } else {
    animateSlides(-1);
  }
}

function updateProgress() {  
  animation.progress(gsap.utils.wrap(0, 1, gsap.getProperty(proxy, "x") / wrapWidth));
}

function resize() {
  var norm = (gsap.getProperty(proxy, "x") / wrapWidth) || 0;
  
  slideWidth = slides[0].offsetWidth;
  wrapWidth = slideWidth * numSlides;
  
  gsap.set(proxy, {
    x: norm * wrapWidth
  });
  
  animateSlides(0);
  slideAnimation.progress(1);
}

function snapX(x) {
  return Math.round(x / slideWidth) * slideWidth;
}

const DOM = {
    sections: {
        columns: document.querySelector('.section--columns'),
        showcase: document.querySelector('.section--showcase'),
    },
    columns: document.querySelectorAll('.section--columns > .columns'),
    columnWraps: document.querySelectorAll('.section--columns .column-wrap'),
    itemsWrappers: document.querySelectorAll('.section--columns .column-wrap .column'),
    items: document.querySelectorAll('.section--columns .column__item'),
    images: document.querySelectorAll('.section--columns .column__item-img'),
};

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
    lenis = new Lenis({
        lerp: 0.2,
        smooth: true,
    });
    const scrollFn = (time) => {
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
    };
    requestAnimationFrame(scrollFn);
};

// GSAP Scroll Triggers
const scroll = () => {
    gsap.timeline({
        scrollTrigger: {
            start: 0,
            end: 'max',
            scrub: true
        }
    })
    .addLabel('start', 0)
    .to(DOM.sections.columns, {
        ease: 'none',
        startAt: {scale: 1.1},
        scale: 1,
    }, 'start')
    .to(DOM.sections.columns, {
        scrollTrigger: {
            trigger: DOM.sections.showcase,
            start: 0,
            end: 'top top',
            scrub: true
        },
        ease: 'power4.inOut',
        startAt: {
            opacity: 0.2,
        },
        opacity: 1,
        // repeat once (go back to "startAt" values)
        yoyo: true,
        repeat: 1
    }, 'start')
    .to(DOM.columnWraps, {
        ease: 'none',
        yPercent: pos => pos%2 ? 3 : -3
    }, 'start')
};

// Preload images
preloadImages('.column__item-img').then(() => {
    document.body.classList.remove('loading');
    // Lenis (smooth scrolling)
    initSmoothScrolling();
    // GSAP Scroll Triggers
    scroll();
});

var parentHeight=gsap.getProperty(".slide-container", "height"); 
console.log(parentHeight);
parentHeight=(parentHeight+70);
gsap.set(".section-slider", {height: parentHeight});